<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.546 16.9375C22.2246 16.9375 21.9163 17.0652 21.689 17.2925C21.4617 17.5198 21.334 17.8281 21.334 18.1495V20.0045C21.3335 20.4209 21.1678 20.8201 20.8732 21.1144C20.5787 21.4088 20.1794 21.5742 19.763 21.5745H3.995C3.5786 21.5742 3.17931 21.4088 2.88478 21.1144C2.59025 20.8201 2.42453 20.4209 2.424 20.0045V18.1495C2.424 17.8281 2.29631 17.5198 2.06901 17.2925C1.84172 17.0652 1.53344 16.9375 1.212 16.9375C0.890558 16.9375 0.582281 17.0652 0.354987 17.2925C0.127692 17.5198 0 17.8281 0 18.1495V20.0045C-8.28124e-07 21.0645 0.42074 22.0812 1.1698 22.8312C1.91887 23.5812 2.935 24.0032 3.995 24.0045H19.763C20.8239 24.0045 21.8413 23.5831 22.5914 22.8329C23.3416 22.0828 23.763 21.0654 23.763 20.0045V18.1495C23.763 17.9899 23.7315 17.8319 23.6703 17.6845C23.609 17.5372 23.5193 17.4033 23.4062 17.2907C23.2932 17.1781 23.159 17.0889 23.0113 17.0283C22.8637 16.9677 22.7056 16.9369 22.546 16.9375Z"
      fill="#0A194D"
    />
    <path
      d="M11.8791 0C11.3649 0 10.8717 0.204287 10.508 0.56792C10.1444 0.931553 9.94013 1.42475 9.94013 1.939V11.005C9.94013 11.0713 9.91379 11.1349 9.8669 11.1818C9.82002 11.2287 9.75643 11.255 9.69013 11.255H7.03113C6.84512 11.2551 6.66306 11.3086 6.50663 11.4093C6.35021 11.5099 6.22603 11.6534 6.14888 11.8227C6.07174 11.992 6.04488 12.1798 6.07152 12.3639C6.09816 12.548 6.17717 12.7206 6.29913 12.861L11.1471 18.437C11.2381 18.5419 11.3505 18.626 11.4768 18.6836C11.6031 18.7412 11.7403 18.7711 11.8791 18.7711C12.0179 18.7711 12.1552 18.7412 12.2814 18.6836C12.4077 18.626 12.5202 18.5419 12.6111 18.437L17.4591 12.861C17.5811 12.7206 17.6601 12.548 17.6867 12.3639C17.7134 12.1798 17.6865 11.992 17.6094 11.8227C17.5322 11.6534 17.408 11.5099 17.2516 11.4093C17.0952 11.3086 16.9131 11.2551 16.7271 11.255H14.0681C14.0018 11.255 13.9382 11.2287 13.8914 11.1818C13.8445 11.1349 13.8181 11.0713 13.8181 11.005V1.939C13.8181 1.42475 13.6138 0.931553 13.2502 0.56792C12.8866 0.204287 12.3934 0 11.8791 0Z"
      fill="#0A194D"
    />
  </svg>
</template>
